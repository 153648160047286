import Player from "@vimeo/player";

const iframePlayer = document.querySelector("#player");
const player = new Player(iframePlayer);
const playBtn = document.querySelector(".play-btn");
playBtn.addEventListener("click", (e) => {
	if (player) {
		playBtn.classList.add("hidden");
		player.play();
	}
});

const observer = new IntersectionObserver(
	(entries) => {
		entries.forEach((_) => {
			if (player) {
				player.pause();
			}
		});
	},
	{
		threshold: 0.2,
	}
);

observer.observe(iframePlayer);
