import { gsap, Linear, Power0 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function buildScrollingTicker() {
	console.log("buildScrollingTicker(){}");

	if (document.querySelectorAll("#scrolling-ticker")[0]) {
		// console.log("YES");
		buildIt();
	} else {
		// console.log("NOOOOOOO");
	}

	function buildIt() {
		let scrollingTicker = document.querySelectorAll("#scrolling-ticker")[0];
		let tickerWrapper = scrollingTicker.querySelectorAll(".ticker-wrapper")[0];
		let tickerList = scrollingTicker.querySelectorAll(".ticker-list")[0];
		let tickerWidth = tickerWrapper.offsetWidth; // Width to scroll
		// console.log(tickerWidth);

		let tickerListDupe = tickerList.cloneNode(true);
		tickerWrapper.append(tickerListDupe);

		let startingPosition = scrollingTicker.offsetWidth * 0.8;
		// This will set the initial starting point of the Ticker scroll.
		gsap.set(tickerWrapper, { left: startingPosition });

		// This will be used to adjust the speed.
		var speed = 50;

		// v = d/t ...
		// speed = moveX / duration ... so,
		// duration = moveX / speed

		// This is the inital tween from off-screen (right) to the starting point (0)
		// So its duration is based on the width of the visible ticker area
		// var initDuration = tickerWidth / speed;
		var initDuration = startingPosition / speed;

		// This is the tween of the entire span holding the text from 0 to -itsWidth
		// So its duration is based on the width of one span

		var loopDuration = tickerWidth / speed;

		var tl = gsap.timeline({
			scrollTrigger: {
				// markers: true,
				trigger: scrollingTicker,
				toggleActions: "play pause resume pause",
				// start: "200 bottom", // when the top of the trigger hits the bottom of the viewport
				// end: "+=500", // end after scrolling 500px beyond the start
			},
		});
		tl.to(tickerWrapper, initDuration, { left: 0, ease: Linear.easeNone });
		tl.to(tickerWrapper, loopDuration, {
			left: -tickerWidth,
			ease: Linear.easeNone,
			repeat: -1,
		});
	}
}
