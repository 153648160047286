const sunsetCountdown = document.querySelector("[data-sunset]");

const sunset = new Date();
// just guessing what months can be feasibly pass for getting dark late vs which ones can't
const sunsetHour = [5, 6, 7, 8, 9].includes(sunset.getMonth()) ? 21 : 18;
let _interval = null;
sunset.setHours(sunsetHour, 0, 0, 0);

const calculateTimeLeft = () => {
	const currTime = new Date();
	const timeUntilSunset = sunset - currTime;

	if (timeUntilSunset < 0 || currTime.getHours() < 8) {
		sunsetCountdown.innerText = "0min";
		sunsetCountdown.classList.remove("opacity-0");
		if (_interval) clearInterval(_interval);
		return false;
	}

	const hoursUntil = (timeUntilSunset / (60 * 1000) / 60).toFixed(0);
	const minutesUntil = ((timeUntilSunset / (60 * 1000)) % 60).toFixed(0);

	let str = "";
	if (hoursUntil > 0) {
		str += `${hoursUntil}hr${hoursUntil !== 0 ? "s" : ""} `;
	}

	str += `${minutesUntil}min${minutesUntil !== 1 ? "s" : ""}`;
	sunsetCountdown.innerText = str;
	sunsetCountdown.classList.remove("opacity-0");
	return true;
};

_interval = setInterval(() => {
	calculateTimeLeft();
}, 60 * 1000);
calculateTimeLeft();
