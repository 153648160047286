// console.log("main.js 1");
// import { gsap, Power2, Sine } from "gsap";
// import { buildAnimations } from "./buildAnimations.js";
import "./sunset-countdown";
import "./video";
import { buildWavesBG } from "./buildBGWave.js";
import { buildScrollingTicker } from "./buildScrollingTicker.js";
import { buildToggle } from "./buildToggle.js";
// import { buildTransitions } from "./buildTransitions.js";
import "./downloadable-scroller";

// console.log("main.js 2");

buildToggle();
buildScrollingTicker();
buildWavesBG();
// buildTransitions();
