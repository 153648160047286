import Swiper, { Navigation, A11y } from "swiper";

const swipers = [...document.querySelectorAll(".swiper")].map((el) => {
	const nextEl = el.querySelector(".swiper-button-next");
	const prevEl = el.querySelector(".swiper-button-prev");
	// console.log(nextEl, prevEl);
	return new Swiper(el, {
		modules: [Navigation, A11y],
		observer: true,
		navigation: {
			nextEl,
			prevEl,
		},
		a11y: true,
		slidesPerView: 1.5,
		spaceBetween: 20,
		// centeredSlides: true,
		breakpoints: {
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			992: {
				slidesPerView: 3,
			},
		},
	});
});
