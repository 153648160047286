import { gsap, Power2, Sine } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function buildToggle() {
	console.log("buildToggle(){}");
	let allToggles = document.querySelectorAll("[data-sunmoon]");

	allToggles.forEach((_sunmoon) => {
		let sunmoon = _sunmoon;
		let toggle = sunmoon.querySelector("[data-toggle] .sunbadToggle__checkbox");
		let moons = sunmoon.querySelectorAll("[data-moon]");
		let suns = sunmoon.querySelectorAll("[data-sun]");

		suns.forEach((_sun) => {
			_sun.classList.add("hidden");
		});

		toggle.addEventListener("change", function () {
			if (toggle.checked) {
				suns.forEach((_sun) => {
					_sun.classList.remove("hidden");
				});
				moons.forEach((_moon) => {
					_moon.classList.add("hidden");
				});
			} else {
				suns.forEach((_sun) => {
					_sun.classList.add("hidden");
				});
				moons.forEach((_moon) => {
					_moon.classList.remove("hidden");
				});
			}
			// SYNC ALL THE TOGGLES
			// // console.log(element.checked);
			// allToggles.forEach((e) => {
			// 	e.checked = element.checked;
			// });
		});
	});

	// function togglePage(TrueOrFalse) {
	// 	allToggles.forEach((element) => {
	// 		element.checked = TrueOrFalse;
	// 	});
	// }
}
