import { gsap, Power2, Sine } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// NEED TO ADD SCROLL TRIGGER TO PAUSE / PLAY THE ANIMATION
// ON ENTER / LEAVE SO THAT IT'S NOT ALWAY PLAYING OUT OF FRAME.
// scrollTrigger: {
// 	id: "circle_" + index,
// 	trigger: element,
// 	// toggleActions: "play pause restart restart",
// 	toggleActions: "play pause restart pause",
// 	// start: "top+=100 bottom-=200",
// 	// markers: true,
// 	// scrub: 0,
// },

function wavesInit() {
	// console.log("wavesInit()");
	let allBGWaves = document.querySelectorAll("[data-bgwaves]");

	allBGWaves.forEach((_container) => {
		makeWaves(_container);
	});
}

function makeWaves(_container) {
	// console.log("makeWaves()", _container);
	let BG = _container;
	let SVG = BG.querySelector("svg");
	let WAVE = BG.querySelector("[data-wave]");
	let COLOR = BG.querySelector("[data-color]");

	let POINTS = [];
	let TOGGLE = BG.parentNode.querySelector("[data-toggle] input");
	// console.log("toggle", TOGGLE);
	let SUNMOON = TOGGLE.checked ? "sun" : "moon";
	// let SUNMOON = "";
	// if (TOGGLE.checked) {
	// 	SUNMOON = "sun";
	// } else {
	// 	SUNMOON = "moon";
	// }

	function phazeWave(sunmoon) {
		// if (sunmoon == "sun") {
		// 	gsap.to(COLOR, { duration: 1, fill: "#FFDD00", ease: Power2.easeOut });
		// } else {
		// 	gsap.to(COLOR, { duration: 1, fill: "#ED1B24", ease: Power2.easeOut });
		// }

		let br = WAVE.points[WAVE.points.length - 2];
		br.x = BG.offsetWidth;
		br.y = BG.offsetHeight;

		let bl = WAVE.points[WAVE.points.length - 1];
		bl.x = 0;
		bl.y = BG.offsetHeight;
	}

	// console.log(containerHeight, containerWidth);
	function rebuildWaves(sunmoon) {
		// console.log(sunmoon);
		// Clear out all the points, so that we can rebuild to the correct size.
		WAVE.setAttribute("points", "");
		POINTS = [];

		let containerHeight = BG.offsetHeight;
		let containerWidth = BG.offsetWidth;

		// COLOR.fill = "green";
		// COLOR.style.fill = TOGGLE.checked ? "goldenrod" : "firebrick";

		// if (sunmoon == "sun") {
		// 	//red
		// 	gsap.set(COLOR, { fill: "#FFDD00" });
		// } else {
		// 	//yellow
		// 	gsap.set(COLOR, { fill: "#ED1B24" });
		// }

		let width = containerWidth;
		// let width = 1000;
		let amplitude = TOGGLE.checked ? "50" : 50; // height of wave
		let frequency = TOGGLE.checked ? "2" : 2; // number of waves
		let segments = 25; // smoothness of the wave
		let interval = width / segments;

		for (var i = 0; i <= segments; i++) {
			let norm = i / segments;
			let point = WAVE.points.appendItem(SVG.createSVGPoint());

			point.x = i * interval;
			point.y = amplitude / 2;

			let tweenPoint = gsap
				.to(point, {
					duration: 2,
					y: -point.y,
					repeat: -1,
					yoyo: true,
					ease: Sine.easeInOut,
				})
				.progress(norm * frequency);

			// add each tween to the array, so that we can reference and kill on resize.
			POINTS[i] = tweenPoint;
		}

		// Bottom right/left points
		let br = WAVE.points.appendItem(SVG.createSVGPoint());
		br.x = containerWidth;
		br.y = containerHeight;

		let bl = WAVE.points.appendItem(SVG.createSVGPoint());
		bl.x = 0;
		bl.y = containerHeight;

		// console.log(WAVE.points.length);
		// console.log(WAVE.points[WAVE.points.length - 1]);
		// console.log(WAVE.points[WAVE.points.length - 2]);
	}

	function wavePlayPause(_true_) {
		if (_true_) {
			// console.log("play BGWaves");
			POINTS.forEach((tween) => {
				tween.play();
			});
		} else {
			// console.log("pause BGWaves");
			POINTS.forEach((tween) => {
				tween.pause();
			});
		}
	}
	// During resize, we PAUSE.
	// On resize complete we KILL and REBUILD.
	let heatWaveDebounce = new GsDebouncing({
		f1: function () {
			// console.log("resizing");
			POINTS.forEach((tween) => {
				// pause the animation:
				tween.pause();
			});
		},
		f2: function () {
			// console.log("resized ", wavesArr.length);
			POINTS.forEach((tween) => {
				// kill the entire animation:
				tween.kill();
				// set to null so the reference is removed
				tween = null;
			});

			rebuildWaves(SUNMOON);
		},
	});
	window.addEventListener("resize", heatWaveDebounce.run);

	//
	TOGGLE.addEventListener("change", function () {
		let _sunmoon = TOGGLE.checked ? "sun" : "moon";
		phazeWave(_sunmoon);
		// rebuildWaves(_sunmoon);
	});

	ScrollTrigger.create({
		trigger: BG,
		start: "top-=30 bottom",
		end: "top+=30 top", // This can be set to limit the triggered area to ONLY the height of the wave, not the whole section.
		// markers: true,
		// onToggle: (self) => console.log("toggled, isActive:", self.isActive),
		// onToggle: (self) => {
		// 	// console.log("toggled, isActive:", self.isActive);
		// 	wavePlayPause(self.isActive);
		// },
		onToggle: (self) => wavePlayPause(self.isActive),
	});
	//
	rebuildWaves();
	//

	// window.addEventListener("load", (event) => {
	// wavesInit();
	// rebuildWaves();
	// });
} //...close... makeWaves(){};

export function buildWavesBG() {
	// console.log("buildWavesBG(){}");
	wavesInit();
}

// Debouncing Utility:
function GsDebouncing(conf) {
	let T = this;
	T.dur = conf.dur || 0.2;
	T.f1 = conf.f1 || function () {};
	T.f2 = conf.f2 || function () {};
	T.toggle = 1;
	T.tw = gsap
		.delayedCall(T.dur, function () {
			T.f2();
			T.toggle = 1;
		})
		.paused(true);
	T.run = function () {
		if (T.toggle) {
			T.f1();
			T.toggle = 0;
		}
		if (!T.toggle) {
			T.tw.restart(true);
		}
	};
	return T;
}
